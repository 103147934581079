import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'

import consumer_orderNo_center_flightRefundList from '@/lib/data-service/haolv-default/consumer_orderNo_center_flightRefundList'
import consumer_orderNo_center_refStatusList from '@/lib/data-service/haolv-default/consumer_orderNo_center_refStatusList'
import consumer_orderNo_center_ref_downloadDR from '@/lib/data-service/haolv-default/consumer_orderNo_center_ref_downloadDR'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
export default {
    data() {
        return {
            loading: false,
            searchForm: {
                deptIds: [],
                deptIdList: [],
                endDate: '',
                startDate: '',
                passengerName: '', //
                feeAffiliation: '', //
                keyword: '',
                orderBy: '',
                refOrderNo: '',
                orderNo: '',   // 订单号
                refStatus: 0, // 退票单状态：0.全部 41.退票中 45.退票成功 46.退票失败 47.已取消

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数
            activeName: '0',
            tabList: [
                // 退票单状态：0.全部 41.退票中 45.退票成功 46.退票失败 47.已取消
                {
                    name: "0",
                    label: "全部",
                    value: 0
                },
                {
                    name: "41",
                    label: "退票中",
                    value: 0
                },
                {
                    name: "45",
                    label: "退票成功",
                    value: 0
                },
                {
                    name: "46",
                    label: "退票失败",
                    value: 0
                },
                {
                    name: "47",
                    label: "已取消",
                    value: 0
                },
            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getDeptList();
        this.getOrderList()
        this.getListNum()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.startDate = moment(val[0]).format('YYYY-MM-DD 00:00:00')
                    this.searchForm.endDate = moment(val[1]).format('YYYY-MM-DD 23:59:59')
                } else {
                    this.searchForm.startDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        onSearch() {
            this.searchForm.currentPage = 1
            this.getOrderList()
            this.getListNum()
        },
        onReset() {
            this.searchForm = {
                deptIds: [],
                deptIdList: [],
                endDate: '',
                startDate: '',
                passengerName: '', //
                feeAffiliation: '', //
                keyword: '',
                orderBy: '',
                refOrderNo: '',
                orderNo: '',   // 订单号
                refStatus: 0, // 退票单状态：0.全部 41.退票中 45.退票成功 46.退票失败 47.已取消

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            }
            this.applyTime = []
            this.onSearch()
        },
        getOrderList() {
            this.loading = true
            this.searchForm.refStatus = parseInt(this.activeName)
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_orderNo_center_flightRefundList(this.searchForm).then(res => {
                this.orderList = res.datas.list
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = []
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            })
        },
        getListNum() {
            consumer_orderNo_center_refStatusList(this.searchForm).then(res => {
                const flightOrderNum = res.datas.countVOList
                this.tabList.forEach(value => {
                    let isHaveNum = false
                    flightOrderNum.forEach(value1 => {
                        if (value1.refundStatus.toString() === value.name) {
                            isHaveNum = true
                            value.value = value1.orderNum
                        }
                    })
                    if (!isHaveNum) {
                        value.value = 0
                    }
                })
            })
        },
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false
            consumer_orderNo_center_ref_downloadDR(this.searchForm).then(res => {
                let url = res.datas.orderDetailsUrl
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-flight-refund-detail',
                query: {
                    orderNo: val.refOrderNo,
                    type: 'orderCenter'
                }
            })
        },
        getStatusToClass(val) {
            // 退票单状态：41.退票中 45.退票成功 46.退票失败 47.已取消
            if (val === 41) {
                return 'yellow-text'
            } else if (val === 35) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
    }
}
